import React from 'react';
import './App.css';
import GraphComponent from './GraphComponent'; // Importing GraphComponent

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* Include the GraphComponent here */}
        <GraphComponent />
      </header>
    </div>
  );
}

export default App;

