import React, { useEffect, useState } from 'react';
import { request, gql } from 'graphql-request';

const GraphComponent = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [data, setData] = useState({
    livingRoom: null,
    basement: null,
    sunroom: null,
    pcPowerUsage: null,
  });

  const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  const endpoint = 'https://iy2ljrgayffsjfgmrr2qtetbmi.appsync-api.us-east-1.amazonaws.com/graphql';

  const refreshToken = async () => {
    const refresh_token = localStorage.getItem('refresh_token');
    if (!refresh_token) return false;

    const tokenEndpoint = `${cognitoDomain}/oauth2/token`;
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('client_id', clientId);
    params.append('refresh_token', refresh_token);

    try {
      const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params.toString(),
      });

      if (!response.ok) {
        throw new Error('Token refresh failed');
      }

      const data = await response.json();
      localStorage.setItem('id_token', data.id_token);
      const expiryTime = new Date().getTime() + data.expires_in * 1000;
      localStorage.setItem('token_expiry', expiryTime.toString());
      localStorage.setItem('refresh_token', data.refresh_token);
      return true;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  };

  const isTokenValid = () => {
    const tokenExpiry = localStorage.getItem('token_expiry');
    return tokenExpiry && new Date().getTime() < parseInt(tokenExpiry, 10);
  };

  const exchangeCodeForToken = async (code) => {
    const tokenEndpoint = `${cognitoDomain}/oauth2/token`;
    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('client_id', clientId);
    params.append('redirect_uri', redirectUri);
    params.append('code', code);

    try {
      const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params.toString(),
      });

      if (!response.ok) {
        throw new Error('Token exchange failed');
      }

      const data = await response.json();
      localStorage.setItem('id_token', data.id_token);
      const expiryTime = new Date().getTime() + data.expires_in * 1000;
      localStorage.setItem('token_expiry', expiryTime.toString());
      localStorage.setItem('refresh_token', data.refresh_token);
      setIsLoggedIn(true);

      window.history.pushState({}, document.title, '/');
    } catch (error) {
      console.error('Error exchanging code for token:', error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      exchangeCodeForToken(code);
    } else if (isTokenValid()) {
      setIsLoggedIn(true);
    } else {
      refreshToken().then((isRefreshed) => {
        setIsLoggedIn(isRefreshed);
        if (!isRefreshed) {
          console.log('Token is expired and refresh failed. Need to login again.');
        }
      });
    }
  }, []);

  const fetchData = async () => {
    const idToken = localStorage.getItem('id_token');

    const queryTemplate = (deviceId) =>
      deviceId === 'pc'
        ? gql`
            query GetLatestDeviceData {
              getLatestDeviceData(device_id: "${deviceId}") {
                Daykwh
              }
            }
          `
        : gql`
            query GetLatestDeviceData {
              getLatestDeviceData(device_id: "${deviceId}") {
                Temperature
              }
            }
          `;

    const locations = ['test', 'basement', 'sunroom', 'pc'];

    try {
      const promises = locations.map((location) =>
        request(endpoint, queryTemplate(location), null, {
          Authorization: `Bearer ${idToken}`,
        })
      );

      const responses = await Promise.all(promises);

      setData({
        livingRoom: responses[0].getLatestDeviceData.Temperature,
        basement: responses[1].getLatestDeviceData.Temperature,
        sunroom: responses[2].getLatestDeviceData.Temperature,
        pcPowerUsage: responses[3].getLatestDeviceData.Daykwh,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchData();
    }
  }, [isLoggedIn]);

  const loginUrl = `${cognitoDomain}/login?client_id=${clientId}&response_type=code&scope=email+openid&redirect_uri=${redirectUri}`;

  const handleLogin = () => {
    window.location.href = loginUrl;
  };

  if (!isLoggedIn) {
    return (
      <div>
        <button onClick={handleLogin}>Login with Cognito</button>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
      <div>
        <h2>Living Room Temperature</h2>
        <p>{data.livingRoom ?? 'Loading...'}°C</p>
      </div>
      <div>
        <h2>Basement Temperature</h2>
        <p>{data.basement ?? 'Loading...'}°C</p>
      </div>
      <div>
        <h2>Sunroom Temperature</h2>
        <p>{data.sunroom ?? 'Loading...'}°C</p>
      </div>
      <div>
        <h2>Todays PC Power Usage</h2>
        <p>{data.pcPowerUsage ?? 'Loading...'} kWh</p>
      </div>
    </div>
  );
};

export default GraphComponent;
